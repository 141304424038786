<template>
	<div id="misc">
		<img
			class="misc-mask"
			height="226"
			:src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
		/>

		<v-img class="misc-tree" src="@/assets/images/misc/tree-4.png"></v-img>
		<template v-if="!error || !error.errorCode">
			<div class="page-title text-center px-5">
				<h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
					<span class="me-2">Page Not Found</span>
					<v-icon color="warning">
						{{ icons.mdiAlert }}
					</v-icon>
				</h2>

				<p class="text-sm">we couldn't find the page you are looking for</p>

				<div class="misc-character d-flex justify-center">
					<v-img max-width="700" src="@/assets/images/3d-characters/error.png"></v-img>
				</div>

				<v-btn color="primary" v-on:click="$router.go(-1)" class="mb-4"> Go to previous page </v-btn>
				<v-btn color="primary" to="/" class="mb-4"> Go to login </v-btn>
			</div>
		</template>
		<template v-else>
			<div class="page-title text-center px-5">
				<h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
					<span class="me-2">{{ error.errorCode }}-{{ Message }}</span>
					<v-icon color="warning">
						{{ icons.mdiAlert }}
					</v-icon>
				</h2>
				<p class="text-sm">{{ error.desc }}</p>

				<div class="misc-character d-flex justify-center">
					<img style="max-width: 500px" :src="error.imagelink" />
				</div>

				<v-btn color="primary" v-on:click="$router.go(-1)" class="mb-4"> Go to previous page </v-btn>
				<v-btn color="primary" to="/" class="mb-4"> Go to login </v-btn>
			</div>
		</template>
	</div>
</template>

<script>
	import { mdiAlert } from '@mdi/js'

	export default {
		setup() {
			return {
				icons: {
					mdiAlert,
				},
				ErrorCode: -10,
				Message: '',
				LandingPageName: '',
				LandingPageUrl: '',
				token: '',
				errors: [
					{
						errorCode: 404,
						title: 'Page not found',
						desc: "Make sure the address is correct and that the page address hasn't changed.",
						showhomebtn: true,
						imagelink: '/assets/errorImages/error.png',
					},
					{
						errorCode: 403,
						title: 'Forbidden',
						desc: 'Your access requested page is forbidden.',
						showhomebtn: true,
						imagelink: '/assets/errorImages/403.jpg',
					},
					{
						errorCode: 401,
						title: 'Unauthorised!',
						desc: 'Your are not authorised to access requested page.',
						showhomebtn: true,
						imagelink: '/assets/errorImages/401.jpg',
					},
					{
						errorCode: -10,
						title: 'Error!',
						desc: 'Something went wrong!',
						showhomebtn: true,
						imagelink: '/assets/errorImages/ersomethingwentwrong.jpg',
					},
					{
						errorCode: -11,
						title: 'Temporarily disabled !',
						desc: "Amazon's file upload/download service is temporarily disabled, Please try again later.",
						showhomebtn: true,
						imagelink: '/assets/errorImages/error.png',
					},
				],
				error: null,
			}
		},
		mounted: function () {
			this.token = localStorage.getItem('token')

			this.IsMasterFranchise = localStorage.getItem('IsMasterFranchise')

			var loginDetails = JSON.parse(localStorage.getItem('loginDetails'))

			this.ErrorCode = this.$route.params.errorCode ? parseInt(this.$route.params.errorCode) : -10

			this.Message = this.$route.params.message ? this.$route.params.message : ''

			this.LandingPageName = loginDetails.data.landingPage

			this.LandingPageUrl = loginDetails.data.landingPageURL

			if (this.ErrorCode) {
				this.error = this.errors.filter(p => p.errorCode == this.ErrorCode)[0]
			}
		},
	}
</script>

<style lang="scss">
	@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
